var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('v-layout',{staticStyle:{"background-color":"white"},attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm5":"","lg5":"","pa-3":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{attrs:{"id":"radial-chart-1"}})])],1)],1),_c('v-flex',{attrs:{"xs1":""}},[_c('center',[_c('v-divider',{attrs:{"vertical":""}})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm5":"","lg5":"","pa-3":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{attrs:{"id":"radial-chart-2"}})])],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm5":"","lg6":"","pl-lg-5":"","p":""}},[_c('v-layout',{staticStyle:{"background-color":"white"},attrs:{"wrap":"","justify-center":""}},[(_vm.shikara)?_c('v-flex',{attrs:{"xs11":"","pt-3":""}},[_c('span',{staticStyle:{"font-family":"LexendRegular","font-weight":"bold"}},[_vm._v(" shikara ")])]):_vm._e(),(_vm.shikara)?_c('v-flex',{attrs:{"xs11":"","pt-1":""}},[_c('v-card',{staticStyle:{"border-radius":"12px"},attrs:{"elevation":"0","color":"#EDEDED"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","lg4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[(
                        _vm.shikara &&
                        _vm.shikara.shikaraid &&
                        _vm.shikara.shikaraid.coverImage
                      )?_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{staticStyle:{"border-radius":"12px 0px 0px 12px"},attrs:{"src":_vm.mediaUURL + _vm.shikara.shikaraid.coverImage,"height":"130px","width":"auto"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs11":"","lg8":"","pa-lg-4":""}},[_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-flex',{attrs:{"xs6":"","align-self-center":""}},[(
                          _vm.shikara &&
                          _vm.shikara.shikaraid &&
                          _vm.shikara.shikaraid.shikaraName
                        )?_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px","font-family":"LexendRegular"}},[_vm._v(" "+_vm._s(_vm.shikara.shikaraid.shikaraName)+" ")]):_vm._e()]),_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"LexendRegular","font-size":"14px","font-weight":"500"}},[_vm._v(" Booking # : ")]),_c('span',{staticStyle:{"color":"#f17343","font-size":"15px","font-family":"LexendFont"}},[_vm._v("   "+_vm._s(_vm.shikara.bookingNo)+" ")])]),(_vm.shikara.customerDetails)?_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-weight":"400","font-size":"14px","font-family":"LexendRegular"}},[_vm._v(" Booked for :   ")]),(_vm.shikara.customerDetails)?_c('span',{staticStyle:{"font-family":"LexendRegular","font-size":"14px","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.shikara.customerDetails.name))]):_vm._e(),(_vm.shikara.customerDetails.phoneNumber)?_c('span',{staticStyle:{"font-family":"LexendRegular","font-size":"14px","font-weight":"400"}},[_vm._v(" ,"+_vm._s(_vm.shikara.customerDetails.phoneNumber)+" ")]):_vm._e()]):_vm._e(),(_vm.shikara.shikaraid)?_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"LexendRegular","font-size":"14px","font-weight":"500"}},[_vm._v(" Pickup/drop :  "),(_vm.shikara.shikaraid.startingLocation)?_c('span',{staticStyle:{"color":"#f17343"}},[_vm._v(" "+_vm._s(_vm.shikara.shikaraid.startingLocation.name)+" ")]):_vm._e()])]):_vm._e()],1)],1)],1)],1)],1):_vm._e(),(_vm.houseboat)?_c('v-flex',{attrs:{"xs11":"","pt-3":""}},[_c('span',{staticStyle:{"font-family":"LexendRegular","font-weight":"bold"}},[_vm._v(" Houseboat ")])]):_vm._e(),(_vm.houseboat)?_c('v-flex',{attrs:{"xs11":"","pt-1":"","pb-4":""}},[_c('v-card',{staticStyle:{"border-radius":"12px"},attrs:{"elevation":"0","color":"#EDEDED"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","lg4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[(
                        _vm.houseboat &&
                        _vm.houseboat.houseBoatId &&
                        _vm.houseboat.houseBoatId.coverImage
                      )?_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{staticStyle:{"border-radius":"12px 0px 0px 12px"},attrs:{"src":_vm.mediaUURL + _vm.houseboat.houseBoatId.coverImage,"height":"130px","width":"auto"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs11":"","lg8":"","pa-lg-4":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs6":"","align-self-center":""}},[(_vm.houseboat)?_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px","font-family":"LexendRegular"}},[_vm._v(" "+_vm._s(_vm.houseboat.houseBoatName)+" ")]):_vm._e()]),_c('v-flex',{attrs:{"xs12":"","align-self-end":""}},[_c('span',{staticStyle:{"font-family":"LexendRegular","font-size":"14px","font-weight":"500"}},[_vm._v(" Booking # : ")]),_c('span',{staticStyle:{"color":"#f17343","font-size":"15px","font-family":"LexendFont"}},[_vm._v("   "+_vm._s(_vm.houseboat.bookingNo)+" ")])]),(_vm.houseboat.fullName)?_c('v-flex',{attrs:{"xs12":"","align-self-start":""}},[_c('span',{staticStyle:{"font-weight":"400","font-size":"14px","font-family":"LexendRegular"}},[_vm._v(" Booked for :   ")]),(_vm.houseboat.fullName)?_c('span',{staticStyle:{"font-family":"LexendRegular","font-size":"14px","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.houseboat.fullName))]):_vm._e(),(_vm.houseboat.phoneNumber)?_c('span',{staticStyle:{"font-family":"LexendRegular","font-size":"14px","font-weight":"400"}},[_vm._v(" ,"+_vm._s(_vm.houseboat.phoneNumber)+" ")]):_vm._e()]):_vm._e(),_c('v-flex',{attrs:{"xs12":"","text-left":"","align-self-end":""}},[_c('span',{staticStyle:{"font-family":"LexendRegular","font-size":"14px","font-weight":"500"}},[_vm._v(" Pickup/drop : "),(
                            _vm.houseboat &&
                            _vm.houseboat.houseBoatId &&
                            _vm.houseboat.houseBoatId.startingLocation
                          )?_c('span',{staticStyle:{"color":"#f17343"}},[_vm._v(" "+_vm._s(_vm.houseboat.houseBoatId.startingLocation.name)+" ")]):_vm._e()])])],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }