var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#182444","spinner":"bar-fade-scale"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#ff6200","right":"","top":"","timeout":"2000"},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-4":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"title1",style:({
              'font-size':
                _vm.$vuetify.breakpoint.name == 'xs'
                  ? '20px'
                  : _vm.$vuetify.breakpoint.name == 'sm'
                  ? '20px'
                  : _vm.$vuetify.breakpoint.name == 'md'
                  ? '25px'
                  : _vm.$vuetify.breakpoint.name == 'lg'
                  ? '25px'
                  : '30px',
            })},[_vm._v("OverView")])])],1),_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.newArr),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-card',{staticClass:"pa-2 mb-4r-4 mb-md-0",attrs:{"elevation":"0"}},[_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-flex',{attrs:{"xs4":"","align-self-center":""}},[_c('v-img',{attrs:{"height":"60px","contain":"","src":item.src}})],1),_c('v-flex',{attrs:{"xs8":"","pa-3":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-weight":"700","font-size":"30px","font-family":"LexendFont"}},[_vm._v(_vm._s(item.count))])]),_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-weight":"400","font-size":"16px","font-family":"LexendFont"}},[_vm._v(_vm._s(item.name))])])],1)],1)],1)],1)],1)}),1)],1),_c('v-flex',{attrs:{"xs12":"","pt-5":""}},[_c('chart')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }